import { NoAny } from "./type"

export const isDefined = <T>(x: T): x is NonNullable<T> =>
    // eslint-disable-next-line eqeqeq
    x != undefined

export const isNotDefined = <T>(x: T | undefined): x is undefined =>
    // eslint-disable-next-line eqeqeq
    x == undefined

export const isNull = <T>(x: T): x is NonNullable<T> =>
    // eslint-disable-next-line eqeqeq
    x != null

export const isNotNull = <T>(x: T | null): x is null =>
    // eslint-disable-next-line eqeqeq
    x == null

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const noAny = <P>(p: P): NoAny<P> => p as any

export const addBy = (i: number) => (value: number) => value + i

export const isStringInvalid = (str: string | undefined): boolean =>
    str === undefined || str.trim().length === 0

import * as A from "fp-ts/Array"
import { flow, pipe } from "fp-ts/function"
import * as S from "fp-ts/string"

import { join as arrayJoin } from "fp-ts-std/Array"
import _ from "lodash"

export const toLowerCase = (values: string[]) =>
    pipe(
        values,
        A.map(S.toLowerCase),
        A.chain(x => x.split(" ")),
    )

export const join = arrayJoin("")
export const joinWithWS = arrayJoin(" ")
export const joinWithDash = arrayJoin("-")

export const shortLocationName = flow(S.replace(/[a-z]/g, ""), S.toLowerCase)

type GetInterpolatedValues<TString extends string> =
    TString extends `${infer TStart}{{${infer TParam}}}${infer TEnd}`
        ? TParam | GetInterpolatedValues<TStart> | GetInterpolatedValues<TEnd>
        : never

export const interpolate = <
    TTemplate extends string,
    TVariables extends Record<GetInterpolatedValues<TTemplate>, unknown>,
>(
    template: TTemplate,
    variables: TVariables,
) => {
    return template.replace(/{{(.*?)}}/gi, (_, key) => {
        const trimmedKey = key.trim().toLowerCase() as keyof TVariables
        const variablesKey = Object.keys(variables).find(
            vKey => vKey.toLowerCase() === trimmedKey,
        )
        return String(variables[variablesKey as keyof TVariables]) || ""
    })
}

export const isEmptyString = (value: unknown) =>
    _.isString(value) && _.trim(value).length === 0

export const isNoneEmptyString = (value: unknown) =>
    _.isString(value) && _.trim(value).length > 0

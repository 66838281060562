import { pipe } from "fp-ts/function"
import { isNoneEmptyString } from "./string"

export const removeQueryParamFromUrl = (param: string) => (url: URL) => {
    const params = new URLSearchParams(url.search)
    params.delete(param)

    return isNoneEmptyString(params.toString())
        ? `${url.href}?${params.toString()}`
        : url.href
}

export const removeQueryParamFromStr = (param: string) => (location: string) =>
    pipe(new URL(location), removeQueryParamFromUrl(param))

export const removeQueryParamFromLocation =
    (param: string) => (location: Location) =>
        pipe(new URL(location.href), removeQueryParamFromUrl(param))

export const base64WithUrlEncode = (str: string) => safeUrlEncode(btoa(str))
export const base64WithUrlDecode = (encodedStr: string) =>
    safeUrlDecode(atob(encodedStr))

export const safeUrlEncode = (str: string) => encodeURIComponent(str)
export const safeUrlDecode = (encodedStr: string) =>
    decodeURIComponent(encodedStr)

export const removeTrailSlash = (url: string) => url.replace(/\/+$/, "")

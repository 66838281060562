import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import LocizeBackend from "i18next-http-backend"
import { initReactI18next } from "react-i18next"

const detectorOptions = {
    // order and from where user language should be detected
    order: [
        "querystring",
        "cookie",
        "localStorage",
        "navigator",
        "path",
        "subdomain",
    ],
    // keys or params to lookup language from
    lookupQuerystring: "lng",
    lookupCookie: "lng",
    lookupLocalStorage: "i18nextLng",
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,
    // cache user language on
    caches: ["localStorage", "cookie"],
    // languages to not persist (cookie, localStorage)
    excludeCacheFor: ["cimode"],
}

export const defaultNS = "account"
export const fallbackNS = defaultNS

i18n.use(LocizeBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "en",
        defaultNS,
        fallbackNS,
        ns: ["feed", "user", "registration", "search", "account"],
        detection: detectorOptions,
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json",
        },
        interpolation: { escapeValue: false },
    })

export default i18n

const origin = window.location.origin
export const privacyPolicyUrl = `${origin}/registration/privacy-policy`
export const termsAndConditionsUrl = `${origin}/registration/terms-and-condition`

type Side = "top" | "bottom" | "left" | "right"

export const getSafeArea = (side: Side) =>
    parseFloat(
        getComputedStyle(document.documentElement).getPropertyValue(
            side === "top"
                ? "--sat"
                : side === "bottom"
                  ? "--sab"
                  : side === "left"
                    ? "--sal"
                    : side === "right"
                      ? "--sar"
                      : "",
        ),
    )
